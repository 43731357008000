import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import Loadable from 'react-loadable'

import { Switch, BrowserRouter as Router, Route} from 'react-router-dom'

import './styles/index.scss'

import 'font-awesome/css/font-awesome.min.css'
// import 'react-quill/dist/quill.snow.css'

import enUS from 'antd/lib/locale-provider/en_US'

import { google_analytics } from '../package.json'

import { LocaleProvider } from 'antd'

import CssBaseline from 'material-ui/CssBaseline'
import { MuiThemeProvider } from 'material-ui/styles'
import { CircularProgress } from 'material-ui/Progress'

import { Theme } from './styles/Styles'

const LoadableControlPanel = Loadable({
    loader: () => import('./components/ControlPanel/index'),
    loading() {
      return <div className="Center"><CircularProgress size={80} /></div>
    }
  })

const LoadableApp = Loadable({
    loader: () => import('./App'),
    loading() {
      return <div className="Center"><CircularProgress size={80} /></div>
    }
  })

class MuiThemeApp extends React.Component {
    render() {
        return (<MuiThemeProvider theme={Theme}>
            <CssBaseline />
            <LoadableApp />
        </MuiThemeProvider>)
    }
}

class Root extends React.Component {
    constructor(props) {
        super(props)
        console.log("[GA] initialize")
        ReactGA.initialize(google_analytics, {
            debug: true,
            titleCase: false,
        })
        this.state = {}
    }

    componentWillReceiveProps(props) {
        console.log("google_analytics : ", google_analytics)
    }

    render() {
        return (<LocaleProvider locale={enUS}>
            <Router>
                <Switch>
                    <Route path="/admin" component={LoadableControlPanel} />
                    <Route component={MuiThemeApp} />
                </Switch>
            </Router>
        </LocaleProvider>)
    }

}

ReactDOM.render(<Root />, document.getElementById('root'))
