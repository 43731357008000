import { createMuiTheme } from 'material-ui/styles'

// import indigo from 'material-ui/colors/indigo'
// import purple from 'material-ui/colors/purple'
import grey from 'material-ui/colors/grey'
import orange from 'material-ui/colors/orange'
// import yellow from 'material-ui/colors/yellow'

export const Theme =  createMuiTheme({
  palette: {
    primary: grey,
    secondary: orange,
  },
  status: {
    danger: 'orange',
  },
})

const drawerWidth = 240

export const AppBarStyles = theme => ({
  // app
  root: {
    flexGrow: 1,
    // height: 430,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    // padding: theme.spacing.unit,
    // backgroundColor: theme.palette.background,
    // color: theme.palette.primary,
  },
  // toolbar start
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary[900],
  },
  // for shifting
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // left
    marginLeft: drawerWidth,
  },
  menuButtons: {
    flexDirection: 'row-reverse',
    marginLeft: 12,
    marginRight: 20,
  },
  // for shifting
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
    // for shifting
    position: 'relative',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
      // for shifting
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // left:
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // left:
    marginLeft: 0,
  },
  textInPrimary: {
    backgroundColor: theme.palette.primary[900],
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.common.white,
  },
  textInSecondary: {
    backgroundColor: theme.palette.primary[900],
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.secondary.main,
  },
  hide: {
    display: 'none',
  },
  // tool bar end
  
  logoImage: {
    width: 20,
    height: 20,
  },

  // generic
  flex: {
    display: 'flex',
  },
})

const Styles = theme => ({
    card: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      marginBottom: 16,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    pos: {
      marginBottom: 12,
      color: theme.palette.text.secondary,
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    avatar: {
      margin: 10,
    },
    bigAvatar: {
      width: 200,
      height: 200,
    },

    cardFlex: {
      display: 'flex',
    },
})

export default Styles